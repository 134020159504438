<template>
  <div :class="{container:true,notScroll:isScroll}">
       <!-- 顶部 -->
    <MoTitle :showLeft="false" title="学术能力智慧评估" @moTitltFn="navPanel"></MoTitle>
    <div class="moAssess">
      <!-- 头部 -->
      <div class="moTitle center">
          <h1 style="color:#fff;font-size: 1.5rem;margin-bottom:20px">学术能力智慧评估</h1>
          <button class="showBtn" @click="$router.push('/moShow')">立即预约演示</button>
      </div>
      <div class="contentBgColor center">
        <h2 class="h2Desc">应用框架</h2>
        <span class="spanDesc"></span>
        <img src="@/assets/solute/smartXsnl/gnt.png" alt="" style="width:100%;margin:30px 0">
      </div>

      <div class="contentBgColor center">
        <h2 class="h2Desc">业务痛点</h2>
        <span class="spanDesc"></span>
        <div class="moValueContentImg">
          <div v-for="(item,index) in valueList" :key="index" class="moValueItemImg">
            <img :src="item.src" alt="" class="moVlaueImg">
            <div class="moValueTextImg">{{item.content}}</div>
          </div>
        </div>
      </div>

      <div class="contentBgColor center">
        <h2 class="h2Desc">解决方案</h2>
        <span class="spanDesc"></span>
        <img src="@/assets/solute/smartXsnl/cjfw.png" alt="" style="width:100%;margin:30px 0 0">
      </div>

      <div class="contentBgColor center">
        <h2 class="h2Desc">评估方案</h2>
        <span class="spanDesc"></span>
        <div class="moValueContentImg">
          <div v-for="(item,index) in pgfaList" :key="index" class="moValueItemImg">
            <img :src="item.src" alt="" class="moVlaueImg">
            <div class="moValueTextImg">{{item.content}}</div>
          </div>
        </div>
      </div>

      <div class="contentBgColor center">
          <h2 class="h2Desc">应用案例</h2>
          <span class="spanDesc"></span>
          <div class="moCase">
            <MoSwiper :list="caseData"></MoSwiper>
          </div>
      </div>
    </div>
    <MoBottom></MoBottom>
    <MoFoot></MoFoot>
  </div>
</template>

<script>
import MoTitle from '@/components/Motitle'
import MoSwiper from '@/components/moswiper'
export default {
  name: 'moAssess',
  components: {
    MoTitle,
    MoSwiper
  },
  data () {
    return {
      isScroll: false,
      valueList: [
        { content: '手工整理材料，文档资料多', src: require('@/assets/solute/SmartCampus/smartOne.png'), hoverScr: require('@/assets/oneHover.png') },
        { content: '材料打印工作量大，线下送审难', src: require('@/assets/solute/SmartCampus/smartTwo.png'), hoverScr: require('@/assets/towHover.png') },
        { content: '线下审阅材料，评审工作量大，易出错', src: require('@/assets/solute/SmartCampus/smartThree.png'), hoverScr: require('@/assets/threeHover.png') },
        { content: '人工跟进，信息容易滞后，耗时长', src: require('@/assets/solute/SmartCampus/smartFour.png'), hoverScr: require('@/assets/fiveHover.png') }
      ],
      pgfaList: [
        { content: '全流程平台化管理，将各环节申报人、送审单位、评估中心、同行专家无缝链接在一起，评审过程全程留痕，确保评审过程合规、公正、可溯。', src: require('@/assets/solute/SmartCampus/smartOne.png'), hoverScr: require('@/assets/oneHover.png') },
        { content: '评审资料在线数字化，支持在线预览与下载，自动匹配专家，方便邀请专家评审和反馈。在线定制评审表单，满足高校个性化需求。', src: require('@/assets/solute/SmartCampus/smartTwo.png'), hoverScr: require('@/assets/towHover.png') },
        { content: '全程跟踪，实时监控专家评审进度，多维度评审数据分析，及时督促调整，确保整体进度可调控。', src: require('@/assets/solute/SmartCampus/smartThree.png'), hoverScr: require('@/assets/threeHover.png') },
        { content: '多维度专家价值评估，建立体系化的专家标签和行为标签，智能化专家洞察，对专家资源库进行智能化圈群分组。', src: require('@/assets/solute/SmartCampus/smartFour.png'), hoverScr: require('@/assets/fiveHover.png') }
      ],
      caseData: [
        { src: require('@/assets/solute/smartXsnl/shpiy.png'), title: '上海教育评估院', p: '上海市高校教师学术能力同行评议平台是在整体规划的指导下，从“智能技术、灵活配置、创新管理”三个层面提升上海教育评估院对全市各校教师学术能力同行评议的管理与服务能力，一方面，通过平台建立一套科学可行的同行评议机制，搭建一个信息丰富、流程灵活、数据分析智能、交互体验友好、评估科学的高校教师学术能力同行评议平台，同时打造一个功能规划合理、性能安全稳定、管理应用便捷的信息化支撑环境，推动高校学术鉴定管理工作实现“降本增效、精准管理”的发展目标，以此保证同行评议的公平公正和规范性。' }
      ]
    }
  },
  methods: {
    navPanel (v) {
      this.isScroll = v
    },
    getHeight () {
      if (window.innerWidth > 800) {
        this.$router.push('/solution/smartXsnl')
      }
    }
  },
  created () {
    // 监听可视窗口宽度
    window.addEventListener('resize', this.getHeight)
    this.getHeight()
  },
  destroyed () {
    window.removeEventListener('resize', this.getHeight)
  }
}
</script>

<style scoped>
* {

  margin: 0;
  padding: 0;
  font-size: 16px;
  color: #222;

}
img {
   object-fit: cover;
}
.container{
  width: 100%;
}
.notScroll{
  height: 0rem;
  overflow: hidden;
}
.moreBtn{
  width: 6rem;
  height: 2rem;
  border-radius: 1.875rem;
  border: 1px solid rgba(211, 218, 228, 0.5);
  line-height: 2rem;
  color: #444444;
  font-size: .875rem;
  background-color: #fff;
}
.h2Desc{
  font-size: 1.375rem;
  color: #222;
  margin-top: 2.5rem;
}
.spanDesc{
  display: inline-block;
  background-color: #D70C0C;
  width: 1.875rem;
  height: .25rem;
  border-radius: .1875rem;
  margin-top: 1.25rem;
}
.center {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.showBtn{
  width: 8.75rem;
  height: 2.5rem;
  background: linear-gradient(135deg, #FF8484 0%, #C61F1F 100%);
  border-radius: 2.125rem;
  border: 0;
  line-height: 2.5rem;
  font-size: .875rem;
  color: #fff;
}
.contentBgColor{
  width: 100%;
    padding: 0 1.25rem;
box-sizing: border-box;
  background: linear-gradient(180deg, #FFFFFF 0%, #F7F5F4 100%);
}

.moTitle{
  position: relative;
  width: 100%;
  height: 15rem;
  background: url("~@/assets/solute/smartAccess/banner.png") center no-repeat;
  padding: 0 2.5rem;
  background-size: cover;
  object-fit: cover;

  color: #fff;
  text-align: center;
    display: flex;
  flex-direction: column;
  justify-content: center;
}
.moAssess{
  padding-top: 2.75rem;
}
.moCase{
  width: 100%;
  margin-top: 1.875rem;
}
.moValueContentImg{
  width: 100%;
  padding: 20px 0;
  box-sizing: border-box;
}
.moValueItemImg{
  display: flex;
  align-items: center;
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  background: #FFFFFF;
  border-radius: 8px;
  margin-bottom: 20px;
  border: 1px solid rgba(220,223,230,.5);
  box-sizing: border-box;
}
.moVlaueImg{
  width: 40px;
  height: 40px;
  object-fit: cover;
}
.moValueTextImg{
  font-size: 16px;
  font-weight: 400;
  color: #222222;
  line-height: 24px;
  margin-left: 20px;
}
</style>
